@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/****************/
/* Mobile first */
/****************/

html,
body,
#main {
  height: 100%;
  background-color: #fff;
}

@keyframes changeBgGradient {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(-60%, -60%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.logo-background {
  position: absolute;
  z-index: 0;
  background: linear-gradient(164deg, #ddf0ff, #4ab0fa, #2ca6b6, #a6daff) repeat;
  width: 300%;
  height: 400%;
  animation: changeBgGradient 30s cubic-bezier(0.42, 0, 0.58, 1) 1s infinite;
}

.logo {
  padding: 20px;
  margin: 0;
  height: 100%;
  z-index: 1;
  position: relative;
}

.logo img {
  max-width: 80%;
  margin: 0 auto;
}
.subheading {
  background-color: #0f2c3e;
  @apply flex w-full self-center relative;
  @apply font-sans text-base text-white text-center;
}
.subheading span {
  @apply w-1/2 px-2;
}
.en.subheading {
  @apply justify-end;
}

/* For en language the adjective comes before Technology
   and for es comes after Tecnología */
.en.subheading .tech-adj:nth-child(-n + 4),
.es.subheading .tech-adj:nth-last-child(-n + 4) {
  position: absolute;
}
.en.subheading .tech-adj:nth-child(-n + 4) {
  left: 0;
}
.es.subheading .tech-adj:nth-last-child(-n + 4) {
  left: 50%;
}
.en.subheading .tech-adj:nth-child(-n + 3),
.es.subheading .tech-adj:nth-last-child(-n + 3) {
  opacity: 0;
}

@keyframes changeTechAdjective {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.subheading .tech-adj {
  animation: changeTechAdjective 8s cubic-bezier(1, -0.2, 1, -0.2) infinite;
}
.en.subheading .tech-adj:nth-child(4),
.es.subheading .tech-adj:nth-last-child(4) {
  animation-delay: 1s;
}
.en.subheading .tech-adj:nth-child(3),
.es.subheading .tech-adj:nth-last-child(3) {
  animation-delay: 3s;
}
.en.subheading .tech-adj:nth-child(2),
.es.subheading .tech-adj:nth-last-child(2) {
  animation-delay: 5s;
}
.en.subheading .tech-adj:nth-child(1),
.es.subheading .tech-adj:nth-last-child(1) {
  animation-delay: 7s;
}

/* Favourite and most used tech */
.tech-logo {
  @apply max-w-full border-0;
  margin: 0 auto;
}
.tech-logo.js {
  max-width: 45%;
}
.tech-logo.k8s {
  max-width: 94%;
}

/* contact us */
.mail-btn {
  background-color: #d8e6ef;
}

/* Medium widget */
.medium-widget-article__image {
  flex: 1 !important;
  margin: 4px !important;
}

.medium-widget-article__content {
  flex: 2 !important;
  margin: 4px !important;
}

/* copyright at the bottom */
.bottom-line {
  @apply w-full px-4 mt-12 p-2 flex items-center justify-end border-t;
}

.bottom-line span {
  font-size: 0.6em;
}

.bottom-line img {
  width: 78px;
}

.bottom-line * {
  margin: 2px;
}

.heroicon-ui-mail {
  fill: #fff;
}

/**********************/
/* Other screen sizes */
/**********************/

@media (min-width: 480px) {
  .logo img {
    max-width: 60%;
  }
}

@screen sm {
  .bottom-line {
    @apply px-6;
  }
}

@screen md {
  .logo img {
    @apply w-1/2;
  }
  .medium-widget-article__image img {
    margin-left: auto !important;
  }
}

@screen lg {
  .logo img {
    @apply w-2/5;
  }
}

@screen xl {
  .logo img {
    @apply w-1/3;
  }
}
